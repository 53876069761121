<template>
  <div>
    <Spinner v-show="cApiSending" />
    <base-header class="pb-6" type="primary">
      <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
    </base-header>
    <div class="container-fluid mt--6">
      <div
        v-show="cPromiseResolved"
        class="alert"
        :class="[cAlertStatus]"
      >
        {{ cAlertMessage }}
      </div>
      <div class="row">
        <div class="col-md-6">
          <CartPromoForm @submit="handleSubmit" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModuleBindingMixin from "@/mixins/views/Store/ModuleBindingMixin";
const { CartPromoViewModule } = ModuleBindingMixin

import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";
import Spinner from "@/components/Atoms/Spinner.vue";
import CartPromoForm from "@/components/CartPromo/CartPromoForm.vue";

export default {
  name: 'CartPromotionView',
  components: {
    CartPromoForm,
    BreadcrumbHeader,
    Spinner
  },
  mixins: [ CartPromoViewModule ],
  computed: {
    breadcrumb() {
      return [
        {title: 'Sklep', link: {name: 'store'}},
        {title: 'Promocje progowe', link: {name: 'cart-promotions'}},
        {title: this.$route.params.id ? `Tag ID: ${this.$route.params.id}` : 'Nowa promocja progowa'}
      ];
    }
  },
  async beforeMount() {
    const id = this.$route.params.id
    if (!id) {
      return await this.$store.dispatch('CartPromoViewModule/resetForm')
    }
    await this.handleMounting(id)
  },
  watch: {
    $route(to, from) {
      this.handleMounting(to.params.id)
    }
  },
  methods: {
    async handleMounting(id){
      await this.$store.dispatch('CartPromoViewModule/resetForm')
      await this.handleFetchItem({ token: this.$store.state.Auth.token, id })
    },
    async handleSubmit(payload) {
      if ( payload.id ) {
        const obj = {
          ...payload,
          data: {
            ...payload.data
          }
        }
        await this.handlePatchItem(obj)
      } else {
        console.log(payload)
        await this.handlePostItem(payload)
      }
    }
  }
};
</script>
