<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <async-item :loading="loading" :height="45">
          <base-input
            name="discount"
            required
            label="Minimalna wartość koszyka"
            v-model="cMinGrossPrice"
          />
        </async-item>
      </div>
      <div class="col-md-6">
        <async-item :loading="loading" :height="45">
          <base-input
            name="discount"
            required
            label="Rabat dla progu"
            v-model="cDiscount"
          />
        </async-item>
      </div>
    </div>
    <div class="text-right">
      <div class="btn btn-outline-danger" @click="$emit('rate-delete', index)">Usuń próg</div>
    </div>
  </div>
</template>

<script>
import AsyncItem from "@/components/AsyncItem.vue";

export default {
  name: "CartPromoRateItem",
  components: { AsyncItem },
  props: {
    minGrossPrice: {
      type: Number,
    },
    discount: {
      type: Number,
    },
    index: {
      type: Number,
      default: () => 0
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    cMinGrossPrice: {
      get() {
        return this.minGrossPrice
      },
      set(newValue) {
        const _value  = Number(newValue)
        this.$emit('change', {
          data: {
            minGrossPrice: _value,
            discount: this.discount
          },
          index: this.index
        })
      }
    },
    cDiscount: {
      get() {
        return this.discount
      },
      set(newValue) {
        const _value  = Number(newValue)
        this.$emit('change', {
          data: {
            minGrossPrice: this.minGrossPrice,
            discount: _value > 100 ? 100 : _value
          },
          index: this.index
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
