<template>
  <card class="c-product-tag">
    <div slot="header">
      <h3>Dodawanie nowej promocji progowej</h3>
    </div>
    <form
      @submit.prevent="handleFormSubmit"
      :class="cApiSending ? 'opacity-60' : ''"
    >
      <div class="row">
        <div class="col-md-8">
          <async-item :loading="loading" :height="45">
            <base-input
              required
              name="name"
              error="Test"
              label="Podaj nazwę promocji"
              v-model="cName"
            />
          </async-item>
        </div>
        <div class="col-md-4">
          <async-item :loading="loading" :height="45">
            <base-input
              label="Czy aktywna?"
            >
              <base-switch
                v-model="cActive"
                on-text="Tak"
                off-text="Nie"
              />
            </base-input>
          </async-item>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input label="Data od">
            <datepicker v-model="cDateFrom" />
          </base-input>
        </div>
        <div class="col-md-6">
          <base-input label="Data do">
            <datepicker v-model="cDateTo" />
          </base-input>
        </div>
      </div>
      <div>
        <h4>Promocje progowe</h4>
        <hr class="my-3"/>
        <CartPromoRateItem
          v-for="(rate, index) in cRates"
          :key="`rate-row-${index}`"
          :index="index"
          :discount="rate.discount"
          :min-gross-price="rate.minGrossPrice"
          @rate-delete="deleteRateItemFromArray"
          @change="updateRateItem"
        />
        <div class="alert alert-warning" v-if="!cRates.length">Brak progów dla promocji</div>
        <hr class="my-3"/>
        <div class="text-right">
          <button class="btn btn-outline-primary btn-block mb-2" type="button" @click="newRatesItem">Dodaj kolejny próg</button>
        </div>
      </div>
      <hr class="my-3"/>
      <div class="pt-4 text-right">
        <async-item :loading="loading" :height="43" :width="86" inline>
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="formStatus === 'sending'"
          >Zapisz</button>
        </async-item>
      </div>
    </form>
  </card>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import ModuleBindingMixin from "@/mixins/views/Store/ModuleBindingMixin";
const { CartPromoViewModule } = ModuleBindingMixin

import Datepicker from "@/components/Datepicker";
import { Select, Option } from 'element-ui'
import AsyncItem from "@/components/AsyncItem.vue";
import CartPromoRateItem from "@/components/CartPromo/CartPromoRateItem.vue";

const valueFactory = (field) => ({
  get() {
    return this.item[field]
  },
  set(value) {
    this.setItem({
      [field]: value
    })
  }
})

export default {
  name: "CartPromoForm",
  components: {
    CartPromoRateItem,
    AsyncItem,
    Datepicker,
    [Select.name]: Select,
    [Option.name]: Option
  },
  mixins: [ CartPromoViewModule ],
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('CartPromoViewModule', [ 'item' ]),
    cName: valueFactory('name'),
    cDateFrom: valueFactory('dateFrom'),
    cDateTo: valueFactory('dateTo'),
    cActive: valueFactory('active'),
    cRates() {
      return this.item.rates
    }
  },
  methods: {
    ...mapMutations('CartPromoViewModule', [ 'setItem', 'newRatesItem', 'deleteRateItemFromArray', 'updateRateItem' ]),
    async handleFormSubmit() {
      const payload = {
        token: this.$store.state.Auth.token,
        data: {
          name: this.cName,
          active: this.cActive,
          dateFrom: this.cDateFrom,
          dateTo: this.cDateTo,
          rates: this.cRates
        },
        ...( this.item.id ? { id: this.item.id } : {})
      }
      console.log(payload)
      this.$emit('submit', payload )
    }
  }
}
</script>

<style scoped>
.opacity-60 {
  opacity: 0.6;
}
</style>
